<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div class="pl-5 pr-5">
<!--      <v-btn-->
<!--        rounded-->
<!--        color="primary"-->
<!--        class="mb-5"-->
<!--        x-small-->
<!--        large-->
<!--        @click="openDialogDiscount"-->
<!--        v-if="is_expired === 0"-->
<!--      >-->
<!--        <v-icon color="white" dark>mdi-plus</v-icon-->
<!--        ><span style="color: white">Add Discount</span>-->
<!--      </v-btn>-->
      <div>
        <v-row>
          <v-col cols="6">
            <b-form-input
              class="mt-2"
              style="float: left; width: 300px"
              placeholder="Search"
              v-model="search_input"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Total Programme</th>
                    <th scope="col">Date</th>
                    <!--                    <th scope="col">Status</th>-->
                    <th scope="col">Amount</th>
                    <th scope="col">Target</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(discount, i) in discounts">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ discount.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ discount.programmes.length }}
                        </p>
                      </td>
                      <td>
                        <p class="mb-0">
                          <span
                            class="text-dark-75 font-weight-bolder font-size-lg"
                            >{{
                              discount.from_date | convertTimestampToDate
                            }}</span
                          >
                          <v-icon class="ml-2"
                            >mdi-arrow-right-bold-outline</v-icon
                          >
                          <span
                            class="ml-2 text-dark-75 font-weight-bolder font-size-lg"
                            >{{
                              discount.to_date | convertTimestampToDate
                            }}</span
                          >
                        </p>
                      </td>
                      <!--                      <td>-->
                      <!--                        <span style="width: 137px;" v-if="discount.status == 0"><span class="label label-warning label-dot mr-2"></span><span class="font-weight-bold text-warning">Disabled</span></span>-->
                      <!--                        <span style="width: 137px;" v-if="discount.status == 1"><span class="label label-primary label-dot mr-2"></span><span class="font-weight-bold text-primary">Active</span></span>-->
                      <!--                      </td>-->
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg pt-4 blue--text text--lighten-1"
                          v-if="discount.type == type.DIRECT"
                        >
                          {{ discount.amount | formatNumber }} VND
                        </p>
                        <span
                          class="label label-lg label-inline font-weight-bold py-4 label-light-info"
                          v-if="discount.type == type.PERCENTAGE"
                          >{{ discount.amount }} %</span
                        >
                      </td>
                      <td v-if="discount.for_partners_only == 0">
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg indigo--text text--lighten-1"
                        >
                          Toàn bộ học sinh của prep
                        </p>
                      </td>
                      <td
                        v-if="discount.for_partners_only == 1"
                        class="d-flex align-items-center"
                      >
                        <template v-for="(partner, i) in discount.partners">
                          <v-tooltip top :key="i" color="#faebd7">
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="d-flex align-items-center"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <div class="symbol symbol-40 symbol-light mr-4">
                                  <span class="symbol-label">
                                    <img
                                      :src="partner.avatar"
                                      class="h-75 align-self-end"
                                    />
                                  </span>
                                </div>
                              </div>
                            </template>
                            <div>
                              <p
                                class="text-dark-75 font-weight-bolder mb-1 font-size-lg"
                              >
                                {{ partner.name }}
                              </p>
                              <!-- <span class="text-muted font-weight-bold d-block">{{marker.email}}</span> -->
                            </div>
                          </v-tooltip>
                        </template>
                      </td>
                      <td>
<!--                        <div style="display: flex">-->
<!--                          <v-tooltip left>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-success btn-sm"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="editDiscount(discount)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color=""-->
<!--                                    >mdi-square-edit-outline</v-icon-->
<!--                                  >-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Edit</span>-->
<!--                          </v-tooltip>-->
<!--                          <template v-if="is_expired === 0">-->
<!--                            <v-tooltip top>-->
<!--                              <template v-slot:activator="{ on, attrs }">-->
<!--                                <button-->
<!--                                  class="btn btn-icon btn-light-success btn-sm ml-2 btn-light-success-custom"-->
<!--                                  v-bind="attrs"-->
<!--                                  v-on="on"-->
<!--                                  @click="openDialogInputDiscount(discount.id)"-->
<!--                                >-->
<!--                                  <span-->
<!--                                    class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                  >-->
<!--                                    <v-icon color=""-->
<!--                                      >mdi-basket-plus-outline</v-icon-->
<!--                                    >-->
<!--                                  </span>-->
<!--                                </button>-->
<!--                              </template>-->
<!--                              <span>Giảm theo số lượng</span>-->
<!--                            </v-tooltip>-->
<!--                          </template>-->
<!--                          <template v-if="discount.status == 1">-->
<!--                            <v-tooltip top>-->
<!--                              <template v-slot:activator="{ on, attrs }">-->
<!--                                <button-->
<!--                                  class="btn btn-icon btn-light-warning btn-sm ml-2"-->
<!--                                  v-bind="attrs"-->
<!--                                  v-on="on"-->
<!--                                  @click="btnDisabledDiscount(discount.id, i)"-->
<!--                                >-->
<!--                                  <span-->
<!--                                    class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                  >-->
<!--                                    <v-icon color="">mdi-block-helper</v-icon>-->
<!--                                  </span>-->
<!--                                </button>-->
<!--                              </template>-->
<!--                              <span>Disabled</span>-->
<!--                            </v-tooltip>-->
<!--                          </template>-->
<!--                          <v-tooltip right>-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                              <button-->
<!--                                class="btn btn-icon btn-light-danger btn-sm ml-2"-->
<!--                                v-bind="attrs"-->
<!--                                v-on="on"-->
<!--                                @click="btnDeleteDiscount(discount.id)"-->
<!--                              >-->
<!--                                <span-->
<!--                                  class="svg-icon svg-icon-md svg-icon-primary"-->
<!--                                >-->
<!--                                  <v-icon color="">mdi-delete</v-icon>-->
<!--                                </span>-->
<!--                              </button>-->
<!--                            </template>-->
<!--                            <span>Delete</span>-->
<!--                          </v-tooltip>-->
<!--                        </div>-->
                      </td>
                    </tr>
                  </template>
                  <template v-if="!discounts.length">
                    <tr>
                      <td style="text-align: center" colspan="8">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
          <!-- <v-col cols="6">
            <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
            ></v-pagination>
          </v-col> -->
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-model="dialogDiscount"
        max-width="1000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form_discount === 'add'" class="headline"
                >Add Discount</span
              >
              <span v-if="type_form_discount === 'update'" class="headline"
                >Edit Discount</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogDiscount = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="pt-0">
                  <v-text-field
                    label="Name"
                    v-model="name_input"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="5">
                  <label style="font-size: 16px; width: 30%" class="mt-1 p-0"
                    >Form Date:
                  </label>
                  <date-picker
                    style="width: 70%"
                    class=""
                    v-model="from_date_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </v-col>
                <v-col cols="1">
                  <v-icon class="mt-2 ml-4"
                    >mdi-arrow-right-bold-outline</v-icon
                  >
                </v-col>
                <v-col cols="5">
                  <label
                    style="font-size: 16px; width: 30%"
                    class="mt-1 p-0 col-3"
                    >To Date:
                  </label>
                  <date-picker
                    style="width: 70%"
                    class=""
                    v-model="to_date_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                  />
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="7" class="d-flex mt-4 pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0 col-3"
                    >Type discount:
                  </label>
                  <v-radio-group
                    v-model="type_input"
                    column
                    class="mt-0 p-0 ml-1 col-9"
                  >
                    <v-radio
                      label="Giảm trực tiếp"
                      :value="type.DIRECT"
                    ></v-radio>
                    <v-radio
                      label="Giảm theo %"
                      :value="type.PERCENTAGE"
                    ></v-radio>
                    <v-radio
                      label="Giảm theo số lương"
                      :value="type.QUANTITY"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="5" class="mt-0">
                  <div
                    class="d-flex"
                    v-if="
                      type_input === type.DIRECT ||
                      type_input === type.PERCENTAGE
                    "
                  >
                    <!--                    <v-text-field type="text" :label="labelPrice" :value="formatPrice(price_input)" @input="setPrice"></v-text-field>-->
                    <v-text-field
                      type="number"
                      :label="labelPrice"
                      v-model="price_input"
                    ></v-text-field>
                    <p
                      v-if="type_input === type.DIRECT"
                      class="mt-6 text-h6 font-weight-bold"
                    >
                      VNĐ
                    </p>
                    <p
                      v-if="type_input === type.PERCENTAGE"
                      class="mt-6 text-h6 font-weight-bold"
                    >
                      %
                    </p>
                    <p
                      v-if="
                        type_input === type.DIRECT &&
                        price_input !== null &&
                        price_input !== ''
                      "
                      class="mt-2 ml-2 text-subtitle-1"
                    >
                      ({{ price_input | formatNumber }})
                    </p>
                  </div>
                  <p
                    v-if="type_input === type.QUANTITY"
                    class="pt-0 mt-0 text-subtitle-1 font-weight-regular"
                  >
                    (Tạo discount xong mới nhập được tạo theo số lượng)
                  </p>
                </v-col>
                <!-- <v-col cols="12" class="m-0 p-0"></v-col> -->
                <v-col cols="12" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0"
                    >Show expired time:
                  </label>
                  <v-radio-group
                    v-model="show_expired_time"
                    row
                    class="mt-0 p-0 ml-3"
                  >
                    <v-radio label="Yes" value="yes"></v-radio>
                    <v-radio label="No" value="no"></v-radio>
                  </v-radio-group>
                </v-col>

                <template v-if="type_input !== type.QUANTITY">
                  <v-col cols="12" class="d-flex pb-0">
                    <label style="font-size: 16px" class="mt-1 p-0"
                      >Áp dụng tất cả programme:
                    </label>
                    <v-switch
                      class="mt-0 pt-0 ml-2"
                      v-model="is_apply_all_programme"
                      :label="is_apply_all_programme === true ? 'Yes' : 'No'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" v-if="is_apply_all_programme === false">
                    <label style="font-size: 16px">Programme</label>
                    <v-autocomplete
                      v-model="programme_ids"
                      :items="programme_tags"
                      color="blue-grey lighten-2"
                      item-text="name.vi"
                      item-value="id"
                      chips
                      filled
                      multiple
                      deletable-chips
                    >
                    </v-autocomplete>
                  </v-col>
                </template>

                <v-col cols="12" class="pb-0">
                  <label style="font-size: 16px">Product Category</label>
                  <v-autocomplete
                    v-model="product_category_ids"
                    :items="product_categories"
                    item-text="name.vi"
                    item-value="id"
                    color="blue-grey lighten-2"
                    filled
                    chips
                    multiple
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="d-flex pb-0">
                  <label style="font-size: 16px" class="mt-1 p-0 col-4"
                    >For Partners Only:
                  </label>
                  <v-radio-group
                    v-model="for_partners_only"
                    row
                    class="mt-0 p-0 ml-1 col-7"
                  >
                    <v-radio label="Yes" value="yes"></v-radio>
                    <v-radio label="No" value="no"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-if="for_partners_only === 'yes'">
                  <label style="font-size: 16px">Partner</label>
                  <v-autocomplete
                    v-model="partner_ids"
                    :items="partner_tags"
                    color="blue-grey lighten-2"
                    item-text="name"
                    item-value="id"
                    chips
                    filled
                    multiple
                    deletable-chips
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_discount === 'add'"
              @click="btnAddOrEditDiscount(1)"
            >
              Add
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form_discount === 'update'"
              @click="btnAddOrEditDiscount(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogDiscount = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <InputQuantityType
      :show_dialog="dialogInputQuantity"
      @setDialog="(e) => (this.dialogInputQuantity = e)"
      :discount_id="idDiscount"
    />
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import handle_ls_search from "@/mixins/handle_ls_search.js";
import InputQuantityType from "./modal/InputQuantityType";

const DIRECT_TYPE = 1;
const PERCENTAGE_TYPE = 2;
const QUANTITY_TYPE = 3;

export default {
  name: "ListDiscount",
  mixins: [handle_ls_search],
  components: {
    InputQuantityType,
    Loading: () => import("vue-loading-overlay"),
  },
  props: ["is_expired"],
  data() {
    return {
      is_call_api: false,
      discounts: [],
      search_input: "",
      data_pagination: {
        current_page: 1,
        total_pages: 1,
      },
      dialogDiscount: false,
      type_form_discount: null,
      for_partners_only: "no",
      show_expired_time: "yes",
      price_input: 0,
      name_input: "",
      type_input: 1,
      from_date_input: null,
      to_date_input: null,
      programme_ids: [],
      programme_tags: [],
      isUpdating: false,
      partner_ids: [],
      partner_tags: [],
      idDiscount: null,
      timer_query: null,
      is_apply_all_programme: false,
      product_categories: [],
      product_category_ids: [],
      type: {
        DIRECT: DIRECT_TYPE,
        PERCENTAGE: PERCENTAGE_TYPE,
        QUANTITY: QUANTITY_TYPE,
      },
      dialogInputQuantity: false,
    };
  },
  computed: {
    labelPrice() {
      if (this.type_input === this.type.DIRECT) {
        return "Price(vnd)";
      }
      if (this.type_input === this.type.PERCENTAGE) {
        return "Price(%)";
      }
      return "";
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    is_expired: function (val) {
      if (val === 0) {
        this.getAllDiscount();
      } else {
        this.getAllDiscountExpired();
      }
    },
    search_input(val) {
      this.actionSearchDiscount(val);
    },
  },
  created() {
    this.is_call_api = true;
    let data_search = this.getDataSearchByKey("discount");
    if (data_search) {
      this.search_input = data_search.query ? data_search.query : "";
    }
    this.setDataDiscount();
    this.getAllProgramme();
    this.getAllPartner();
    this.getAllProductCategory();
    this.is_call_api = false;
  },
  methods: {
    setDataDiscount() {
      if (this.is_expired === 0) {
        this.getAllDiscount();
      } else {
        this.getAllDiscountExpired();
      }
    },
    async getAllProductCategory() {
      let vm = this;
      await ApiService.get("prep-app/product-category/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          vm.product_categories = response.data;
        }
      });
    },
    async getAllProgramme() {
      let seft = this;
      await ApiService.get("prep-app/programme/limit").then(function (
        response
      ) {
        if (response.status === 200) {
          seft.programme_tags = response.data;
        }
      });
    },
    async getAllPartner() {
      let seft = this;
      await ApiService.get("prep-app/partner").then(function (response) {
        if (response.status === 200) {
          seft.partner_tags = response.data;
        }
      });
    },
    async getAllDiscount(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/discount?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          vm.discounts = response.data;
          vm.is_call_api = false;
        }
      });
    },
    async getAllDiscountExpired(page = 1, query = "") {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/discount/expired?page=" + page + "&name=" + query
      ).then(function (response) {
        if (response.status == 200) {
          vm.discounts = response.data;
          vm.is_call_api = false;
        }
      });
    },
    removeOptionTag(item, type) {
      let index;
      if (type == "programme") {
        index = this.programme_ids.indexOf(item.id);
        if (index >= 0) this.programme_ids.splice(index, 1);
      } else if (type == "partner") {
        index = this.partner_ids.indexOf(item.id);
        if (index >= 0) this.partner_ids.splice(index, 1);
      }
    },
    openDialogDiscount() {
      this.type_form_discount = "add";
      this.dialogDiscount = true;
      this.defaultDataDiscount();
    },
    defaultDataDiscount() {
      this.name_input = "";
      this.from_date_input = null;
      this.to_date_input = null;
      this.type_input = 1;
      this.price_input = "0";
      this.programme_ids = [];
      this.for_partners_only = "no";
      this.show_expired_time = "yes";
      this.partner_ids = [];
      this.is_apply_all_programme = false;
      this.product_category_ids = [];
    },
    editDiscount(discount) {
      this.type_form_discount = "update";
      this.idDiscount = discount.id;
      this.name_input = discount.name;
      this.from_date_input = this.$options.filters.convertTimestampToDate(
        discount.from_date
      );
      this.to_date_input = this.$options.filters.convertTimestampToDate(
        discount.to_date
      );
      this.type_input = discount.type;
      this.price_input = this.formatPrice(discount.amount);
      this.programme_ids = discount.programmes.map((e) => {
        return e.id;
      });
      this.for_partners_only = discount.for_partners_only === 1 ? "yes" : "no";
      this.show_expired_time = discount.show_expired_time === 1 ? "yes" : "no";
      this.partner_ids = discount.partners.map((e) => {
        return e.id;
      });
      this.is_apply_all_programme = discount.is_apply_all_programme === 1;
      this.product_category_ids = discount.product_categories.map(
        (product_category) => {
          return product_category.id;
        }
      );
      this.dialogDiscount = true;
    },
    async btnAddOrEditDiscount(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        name: this.name_input,
        type: this.type_input,
        amount: this.price_input,
        from_date: this.from_date_input,
        to_date: this.to_date_input,
        for_partners_only: this.for_partners_only === "yes" ? 1 : 0,
        programme_ids: this.programme_ids,
        show_expired_time: this.show_expired_time === "yes" ? 1 : 0,
        is_apply_all_programme: this.is_apply_all_programme === true ? 1 : 0,
      };
      if (this.type_input === type.QUANTITY) {
        data.amount = 0;
        data.programme_ids = [];
        data.is_apply_all_programme = 0;
      }
      if (this.for_partners_only === "yes") {
        data.partner_ids = this.partner_ids;
      }
      if (this.product_category_ids.length > 0)
        data.product_category_ids = this.product_category_ids;
      if (type === 1) {
        await ApiService.post("prep-app/discount", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.setDataDiscount();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogDiscount = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        await ApiService.put("prep-app/discount/" + vm.idDiscount, data)
          .then(function (res) {
            if (res.status === 202) {
              vm.setDataDiscount();
              Swal.fire({
                title: "",
                text: "The application has been successfully submitted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              vm.dialogDiscount = false;
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    btnActiveDiscount(id_discount, indexDiscount) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.value) {
          ApiService.put("prep-app/discount/" + id_discount + "/active")
            .then(function (res) {
              if (res.status == 202) {
                vm.discounts[indexDiscount].status = 1;
                // vm.setDataDiscount();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    btnDisabledDiscount(id_discount, indexDiscount) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to change?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change it!",
      }).then((result) => {
        if (result.value) {
          ApiService.put("prep-app/discount/" + id_discount + "/disable")
            .then(function (res) {
              if (res.status == 202) {
                vm.discounts[indexDiscount].status = 0;
                // vm.setDataDiscount();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    btnDeleteDiscount(id_discount) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/discount/" + id_discount)
            .then(function (res) {
              if (res.status == 202) {
                vm.setDataDiscount();
              }
            })
            .catch(function (error) {
              console.log(error);
              if (
                error.response.status === 422 ||
                error.response.status === 409
              ) {
                let data_response_error =
                  error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
        }
      });
    },
    setPrice($event) {
      this.price_input = $event;
    },
    formatPrice(value) {
      let stringMoney = value.toString();
      stringMoney.replace(",", "");
      let money = parseInt(stringMoney.replace(/\D/g, ""), 10);
      if (Number.isNaN(money)) return 0;
      return money.toLocaleString();
    },
    actionSearchDiscount(query) {
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        let discount = {
          query: query,
        };
        vm.saveDataSearchToLs("discount", discount);
        if (vm.is_expired === 0) {
          vm.getAllDiscount(1, query);
        } else {
          vm.getAllDiscountExpired(1, query);
        }
      }, 1200);
    },
    validateData() {
      let flat = true;
      if (this.name_input === "" || this.name_input == null) {
        this.$toasted.error("Chưa nhập tên chương trình giảm giá !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.from_date_input == null) {
        this.$toasted.error("Hãy nhập thời gian bắt đầu !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.to_date_input == null) {
        this.$toasted.error("Hãy nhập thời gian kết thúc !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.type_input !== this.type.QUANTITY) {
        if (this.price_input == null || this.price_input < 0) {
          this.$toasted.error("Giá tiền giảm không được nhỏ hơn 0 !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.from_date_input != null && this.to_date_input != null) {
        if (
          Date.parse(this.from_date_input) >= Date.parse(this.to_date_input)
        ) {
          this.$toasted.error(
            "Thời gian kết thúc phải lớn hơn thời gian bắt đầu !!",
            { theme: "toasted-primary", position: "top-right", duration: 4000 }
          );
          flat = false;
        }
        if (this.type_form_discount === "add") {
          if (
            Date.parse(this.from_date_input) <
            Date.parse(
              this.$options.filters.convertTimestampToDate(Date.now() / 1000)
            )
          ) {
            this.$toasted.error(
              "Thời gian bắt đầu không thể là thời gian trong quá khứ !!",
              {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              }
            );
            flat = false;
          }
        }
      }
      return flat;
    },
    openDialogInputDiscount(discount_id) {
      this.idDiscount = parseFloat(discount_id);
      this.dialogInputQuantity = true;
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}

.btn.btn-light-success-custom {
  color: #00e5ff;
  background-color: #c0f9ff;
  border-color: transparent;
}
.btn.btn-light-success-custom i {
  color: #00e5ff;
}
.btn.btn-light-success-custom:hover:not(.btn-text):not(:disabled):not(
    .disabled
  ),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #00e5ff;
}
</style>
