<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogQuantityType"
        max-width="1200px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline"
                >Cấu hình giảm giá theo số lượng ({{ discount_name }})</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogQuantityType = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="4" class="ml-2 p-0 mt-8">
                      <v-radio-group
                        v-model="type_input"
                        row
                        class="mt-0 p-0 ml-1"
                        dense
                        label="Type Discount"
                      >
                        <v-radio
                          label="Giảm trực tiếp"
                          :value="type.DIRECT"
                        ></v-radio>
                        <v-radio
                          label="Giảm theo %"
                          :value="type.PERCENTAGE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="4" class="ml-2 p-0 mt-8">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        label="Amount"
                        v-model="amount_input"
                      ></v-text-field>
                      <p
                        v-if="
                          type_input === type.DIRECT &&
                          amount_input !== null &&
                          amount_input !== ''
                        "
                        class="m-0 p-0 text-subtitle-1"
                        style="margin-top: -1.8rem !important"
                      >
                        ({{ amount_input | formatNumber }} VNĐ)
                      </p>
                    </v-col>
                    <v-col cols="2" class="ml-6 p-0 mt-8">
                      <v-text-field
                        type="number"
                        dense
                        outlined
                        label="Quantity"
                        v-model="quantity_input"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        color="cyan"
                        class="ma-2 font-weight-bold"
                        outlined
                        small
                        @click="createOrUpdateItemQuantity()"
                      >
                        <v-icon small left>mdi-check</v-icon>
                        {{ type_form === "add" ? "Thêm" : "Sửa" }}
                      </v-btn>
                      <v-btn
                        color="#E53935"
                        class="font-weight-bold"
                        outlined
                        small
                        @click="resetInputQuantity()"
                      >
                        <v-icon small left>mdi-reload</v-icon>
                        Làm mới
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <table
                    class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Số lượng</th>
                        <th scope="col">Giảm</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, i) in data_quantity_type">
                        <tr :key="i">
                          <td scope="row">{{ i + 1 }}</td>
                          <td>
                            <p
                              class="text-dark-75 font-weight-bolder d-block font-size-lg"
                            >
                              {{ item.quantity | formatNumber }}
                            </p>
                          </td>
                          <td>
                            <p
                              v-if="item.type === 1"
                              class="text-dark-75 font-weight-bolder d-block font-size-lg pt-4 blue--text text--lighten-1"
                            >
                              {{ item.amount | formatNumber }} VND
                            </p>
                            <p
                              v-if="item.type === 2"
                              class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                            >
                              {{ item.amount }} %
                            </p>
                          </td>
                          <td>
                            <div style="display: flex">
                              <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                  <button
                                    class="btn btn-icon btn-light-primary btn-sm"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="editItemQuantity(i)"
                                  >
                                    <span
                                      class="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <v-icon color=""
                                        >mdi-square-edit-outline</v-icon
                                      >
                                    </span>
                                  </button>
                                </template>
                                <span>Edit</span>
                              </v-tooltip>
                              <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                  <button
                                    class="btn btn-icon btn-light-danger btn-sm ml-4"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="deleteItemQuantity(i)"
                                  >
                                    <span
                                      class="svg-icon svg-icon-md svg-icon-primary"
                                    >
                                      <v-icon color="">mdi-delete</v-icon>
                                    </span>
                                  </button>
                                </template>
                                <span>Delete</span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogQuantityType = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";

export default {
  name: "InputQuantityType",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    discount_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      is_call_api: false,
      type_input: 1,
      type: {
        DIRECT: 1,
        PERCENTAGE: 2,
      },
      data_quantity_type: [],
      amount_input: null,
      quantity_input: null,
      type_form: "add",
      discount_name: "",
      item_quantity_id: null,
    };
  },
  computed: {
    dialogQuantityType: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    dialogQuantityType(val) {
      if (val) {
        this.getDataItemQuantity();
      }
    },
  },
  methods: {
    async getDataItemQuantity() {
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get(
        "prep-app/discount/" + this.discount_id + "/item-quantity"
      ).then(function (response) {
        if (response.status === 200) {
          vm.data_quantity_type = response.data.item_quantity;
          vm.discount_name = response.data.discount_name;
          vm.is_call_api = false;
        }
      });
    },
    resetInputQuantity() {
      this.type_input = 1;
      this.amount_input = null;
      this.quantity_input = null;
      this.type_form = "add";
    },
    validateData() {
      let flat = true;
      if (this.quantity_input === null || this.quantity_input <= 0) {
        this.$toasted.error("Điều kiện giảm theo số lượng phải lớn hơn 0!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.amount_input === null || this.amount_input <= 0) {
        this.$toasted.error("Số giảm phải lớn hơn 0 !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    async createOrUpdateItemQuantity() {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        type: this.type_input,
        quantity: this.quantity_input,
        amount: this.amount_input,
      };
      this.is_call_api = true;
      if (this.type_form === "add") {
        await ApiService.post(
          "prep-app/discount/" + this.discount_id + "/item-quantity",
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getDataItemQuantity();
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else if (this.type_form === "update") {
        await ApiService.put(
          "prep-app/discount/" +
            this.discount_id +
            "/item-quantity/" +
            this.item_quantity_id,
          data
        )
          .then(function (res) {
            if (res.status === 200) {
              vm.is_call_api = false;
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getDataItemQuantity();
            }
          })
          .catch(function (error) {
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
      vm.resetInputQuantity();
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    editItemQuantity(index) {
      let data = this.data_quantity_type[index];
      this.item_quantity_id = data.id;
      this.type_input = data.type;
      this.amount_input = data.amount;
      this.quantity_input = data.quantity;
      this.type_form = "update";
    },
    deleteItemQuantity() {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          vm.is_call_api = true;
          ApiService.delete(
            "prep-app/discount/" +
              this.discount_id +
              "/item-quantity/" +
              this.item_quantity_id
          )
            .then(function (res) {
              if (res.status === 200) {
                vm.$toasted.success("Xóa thành công !!", {
                  theme: "toasted-primary",
                  position: "top-right",
                  duration: 4000,
                });
                vm.getDataItemQuantity();
                vm.is_call_api = false;
              }
            })
            .catch(function (error) {
              console.log(error);
              vm.is_call_api = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
